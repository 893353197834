<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="isOpenModal"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      width="600px"
      @closed="closeModal" @open="openModal">
      <div>
        <ch-form
          :render-option="option"
          :model="modalForm"
          ref="modalForm"
          :rules="rules"
          :props="{ labelWidth: '90px', paddingRight: '0px'}"
        >
          <template #tree>
            <el-tree
              :data="menuList"
              show-checkbox
              check-strictly
              node-key="id"
              ref="tree"
              :default-checked-keys="defaultCheckedKeys"
              :props="defaultProps" >
            </el-tree>
          </template>
        </ch-form>
      </div>
      <span slot="footer" class="flex_con" v-if="type === 'watch'">
        <ch-button @click="closeModal" type="cancel">关闭</ch-button>
        </span>
      <span slot="footer" class="flex_con" v-else>
        <ch-button @click="closeModal" type="cancel">取消</ch-button>
        <ch-button
          @click="handleModal('modalForm')"
          :loading="isButtonLoading"
          type="ok">确定</ch-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  props: {
    getListFunction: {
      type: Function
    }
  },
  data(){
    return {
      title: '',
      modalForm: {},
      isOpenModal: false,
      isButtonLoading: false,
      type: '',
      rules: {
        roleName: [{required: true, message: "请输入角色名称", trigger: ["blur", "change"]}],
      },
      defaultProps: {
        children: "children",
        label: "menuName",
      },
      defaultCheckedKeys: [],
      treeData: [],
    }
  },
  computed: {
    option: function () {
      if (this.type === 'watch' && this.isOpenModal) {
        return [
          {type: 'text', label: '角色名称：', prop: 'roleName', no: '暂无'},
          {type: 'text', label: '角色顺序：', prop: 'roleSort', no: '暂无'},
          {type: 'text', label: '角色状态：', prop: 'status', no: '使用中', yes: '已禁用'},
        ]
      } else if (this.type === 'edit') {
        return [
          {type: 'input', label: '角色名称：', prop: 'roleName', placeholder: "请输入角色名"},
          {type: 'input', label: '角色顺序：', prop: 'roleSort', placeholder: "请输入角色顺序" },
          {type: 'slot', label: '权限：',slotName: 'tree' },
        ]
      } else {
        return [
          {type: 'input', label: '角色名称：', prop: 'roleName', placeholder: "请输入角色名"},
          {type: 'number', label: '角色顺序：', prop: 'roleSort', placeholder: "请输入角色顺序" },
        ]
      }
    },
    ...mapGetters(['menuList'])
  },
  methods: {
    ...mapActions({
      getRoleOption: 'system/getRoleOption'
    }),
    // 打开前处理
    openModal() {
      this.$nextTick(() => {
        this.$refs.modalForm?.clearValidate();
      })
    },
    openWatch(row) {
      this.type = 'watch'
      this.title = '查看角色信息'
      this.modalForm = JSON.parse(JSON.stringify(row))
      this.isOpenModal = true
    },
    openAdd() {
      this.type = 'add'
      this.title = '新增角色'
      this.modalForm = {
        roleName: "",
        roleKey: "",
        menuIds: [],
        postIds: [],
        roleSort: "",
        status: "0",
      }
      this.isOpenModal = true
    },
    openEdit(row) {
      this.type = 'edit'
      this.title = '编辑角色信息'
      this.modalForm = JSON.parse(JSON.stringify(row))
      this.defaultCheckedKeys = this.modalForm.menuIds
      this.isOpenModal = true
    },
    // 执行 增加 或 修改
    handleModal(formRef) {
      this.$refs[formRef].validate((valid) => {
        if (valid) {
          this.isButtonLoading = true
          let parma = { ...this.modalForm }
          if(this.type === 'add') {
            this.$curl.post("/hm/role/add", parma)
              .then(() => {
                this.$message.success("提交成功！");
                this.closeModal()
                this.getListFunction?.()
                this.getRoleOption(true)
              }).catch(() => this.isButtonLoading = false)
          }else {
            let half = this.$refs?.tree?.getHalfCheckedNodes()?.map((item) => item.id)
            parma.menuIds = [...this.$refs?.tree.getCheckedKeys(),...half]
            this.$curl.post("/hm/role/edit", parma)
              .then(() => {
                this.$message.success("提交成功！");
                this.closeModal()
                this.getListFunction?.()
                this.getRoleOption(true)
              }).catch(() => this.isButtonLoading = false)
          }
        }
      });
    },
    // 关闭前清除数据
    closeModal() {
      this.modalForm = {}
      this.isOpenModal = false
      this.isButtonLoading = false
      this.defaultCheckedKeys = []
    },
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-tree {
  height: 200px;
  overflow: auto;
}
</style>